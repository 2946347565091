.headerStep {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logoScoutEurope {
    width: 40px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .troupName {
      color: #4E66E4;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .mainTitle {
    font-weight: 700;
    font-size: 33px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 500px;
    text-align: center;
  }

  .subtitle {
    width: 420px;
    max-width: 85vw;
    text-align: center;
    font-size: 15px;
    color: #696B71;
  }
}

@media(max-width: 980px) {

  .headerStep {
    width: 100vw;
    .logoScoutEurope {
      width: 55px;
    }

    .mainTitle {
      font-size: 28px;
      max-width: 90vw;
    }
  }
}

.containerButton {
  display: flex;
  flex-direction: column;
  align-items: center;

  .blueButton:hover {
    opacity: 0.7;
  }

  .blueButton {
    max-width: 90vw;
    margin-top: 30px;
    background-color: #4E66E4;
    height: 45px;
    width: 430px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    border-width: 0;
  }

  .subtitleButton {
    color: #2B4AD1;
    font-size: 13px;
    font-weight: 600;
  }
}


.doubleInput {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -10px;

  .containerLittleInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .titleInput {
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }

    .littleInput {
      width: 195px;
      max-width: calc((100vw - 100px) / 2);
      height: 38px;
      border-radius: 8px;
      padding-left: 10px;
      border: solid 1px #EAEAEA;
      font-size: 14px;
      font-weight: 500;
      outline: none;
    }
  }
}

.singleInput {
  margin-bottom: 10px;
  .titleInput {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
  }

  .input {
    width: 420px;
    max-width: calc(100vw - 60px);
    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
  }

  textarea {
    padding-top: 10px;
  }

  select {
    width: 435px;
    max-width: calc(100vw - 45px);

    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none; /* Supprime le style par défaut sous Chrome, Safari */
    -moz-appearance: none; /* Supprime le style par défaut sous Firefox */
    appearance: none; /* Supprime le style par défaut pour les autres navigateurs */
    background-color: white;
    cursor: pointer; /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
  }

  .leftArrow {
    margin-left: -25px;
    margin-right: 17px;
    margin-top: 3px;
    width: 7px;
    transform: rotate(90deg);
  }
}

.multipleChoice {
  .titleInput {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    max-width: 90vw;
  }

  .listChoices {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .choiceItem:hover {
    color: #4E66E4;
    font-weight: 500;
    border: solid 1px #5b6fd3;
  }

  .choiceItemSelected, .choiceItem {
    max-width: calc(100vw - 66px);
    width: 410px;
    height: 38px;
    border-radius: 8px;
    padding-left: 12px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    justify-content: space-between;
    padding-right: 12px;
  }

  .choiceItemSelected {
    color: #4E66E4;
    font-weight: 500;
    border: solid 1px #5b6fd3;
  }
}

.infosSquare {
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  border: solid 1px #EAEAEA;
  border-radius: 8px;
  margin-top: 20px;
  max-width: 90vw;

  .titleInfosSquare {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
  }

  .subtitleInfosSquare {
    width: 380px;
    max-width: 80vw;
    text-align: center;
    font-size: 15px;
    color: #696B71;
    margin-bottom: 25px;
  }

  .buttonImportPictures:hover {
    opacity: 0.7;
  }

  .buttonImportPictures {
    border: solid 1px #2D56D5;
    color: #2D56D5;
    width: 350px;
    max-width: calc(100vw - 90px);
    margin-bottom: 30px;
    border-radius: 8px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;

    input {
      display: none;
    }
  }

  .photosContainer {
    display: flex;
    flex-direction: row;
    width: 350px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 2px;

    .picture {
      width: calc(25% - 4px);
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  .deletePictures {
    font-size: 14px;
    color: #EA3322;
    cursor: pointer;
  }

  .squareLink {
    max-width: 350px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    padding: 10px 10px;
    border-radius: 8px;
    margin-bottom: 30px;
    color: #000;

    span:hover {
      opacity: 0.7;
    }

    span {
      color: #2B4AD1;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.rib {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .ligeRib {
    width: 425px;
    max-width: calc(100vw - 43px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .containerValueRIB {
      border: solid 1px #EAEAEA;
      border-radius: 8px;
      padding: 10px 10px;
    }
  }

}


.contact {
  font-size: 13px;
  max-width: calc(100vw - 60px);
  text-align: center;

  a {
    text-decoration: underline;
  }
}
