.landingPage {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;

  .firstPart {
    width: 100vw;
    display: flex;

    .leftPartFirstPart {
      width: 50vw;
      padding-left: 40px;
      padding-top: 40px;

      .logoScoutEurope {
        width: 50px;
      }

      .troupeName {
        font-weight: 600;
        font-size: 18px;
        margin-left: 20px;
      }

      .centerPart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        .mainTitle {
          letter-spacing: 0.5px;
          font-weight: 600;
          margin: 0;
          font-size: 50px;
          max-width: 400px;
          text-align: center;
        }

        .subtitle {
          margin-top: 40px;
          max-width: 450px;
          text-align: center;
          color: #7D88A4;
        }

        .participateButton {
          background-color: #4E66E4;
          color: white;
          padding: 13px 24px;
          border-radius: 99px;
          font-size: 14px;
          font-weight: 600;
          margin-top: 40px;
        }

        .environ {
          color: #4E66E4;
          font-weight: 700;
          font-size: 13px;
          margin-bottom: 40px;
        }

        .letter {
          margin-bottom: 100px; /* Espacement au bas */
          font-family: 'Helvetica Neue', Arial, sans-serif; /* Police moderne et épurée */
          font-size: 16px; /* Taille de police adaptée pour un design moderne */
          line-height: 1.8; /* Espacement entre les lignes pour une meilleure lisibilité */
          color: #333; /* Couleur de texte neutre */
          background-color: #fff; /* Fond blanc pour une approche épurée */
          padding: 40px; /* Padding généreux pour aérer le contenu */
          border: none; /* Pas de bordure pour un look épuré */
          box-shadow: 0 0 0 0px rgba(0,0,0,0.1); /* Ombre portée douce pour de la profondeur */
          border-radius: 8px; /* Coins arrondis pour une touche moderne */
          max-width: 700px; /* Contrôle de la largeur pour la lisibilité */
          margin-left: auto; /* Centrage du bloc */
          margin-right: auto;

          .letter p {
            margin-bottom: 1.5em; /* Espacement en bas de chaque paragraphe */
            text-indent: 0px; /* Pas d'indentation pour un look plus moderne */
            text-align: justify; /* Justification du texte pour un aspect soigné */
          }

          .letter p:first-child::first-letter {
            float: left;
            font-size: 3em; /* Taille de la première lettre */
            line-height: 0.8; /* Ajustement de la ligne de base de la première lettre */
            margin-right: 8px; /* Espacement après la première lettre */
            font-weight: bold; /* Mettre en gras la première lettre */
          }
        }


      }
    }

    .rightPartFirstPart {
      position: fixed;
      right: 0;
      width: 50vw;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 100vh;
      max-height: 1000px;
      flex-direction: column;

      .aquarelle {
        width: calc(50vw - 100px);
        height: 100vh;
        max-height: 900px;
        border-radius: 20px;
        margin-right: 30px;
        object-fit: cover;
      }

      .credit {
        margin-top: -30px;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        width: calc(50vw - 125px);
      }
    }
  }

  @media (max-width: 800px) {
    .firstPart {
      flex-direction: column-reverse;

      .rightPartFirstPart {
        width: 100vw;
        height: 300px;
        margin-top: 25px;
        display: flex;
        position: relative;

        .aquarelle {

          width: calc(100vw - 50px);
          height: 300px;
          border-radius: 10px;
          object-fit: cover;
        }

        .credit {
          margin-top: -30px;
          font-weight: 600;
          font-size: 13px;
          color: #FFFFFF;
          display: flex;
          width: calc(100vw - 85px);
        }
      }

      .leftPartFirstPart {
        width: 100vw;
        padding-left: 0px;
        padding-top: 20px;

        .logoScoutEurope {
          width: 30px;
          margin-left: 30px;
        }

        .troupeName {
          font-weight: 600;
          font-size: 15px;
          margin-left: 15px;
        }

        .centerPart {
          padding-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100vw;

          .mainTitle {
            letter-spacing: 0.5px;
            font-weight: 600;
            margin: 0;
            font-size: 35px;
            max-width: 90vw;
            text-align: center;
          }

          .subtitle {
            margin-top: 40px;
            max-width: 90vw;
            text-align: center;
            color: #7D88A4;
          }

          .participateButton:hover {
            transform: scale(1.02);
          }

          .participateButton {
            background-color: #4E66E4;
            color: white;
            padding: 13px 24px;
            border-radius: 99px;
            font-size: 14px;
            font-weight: 600;
            margin-top: 40px;
            transition: transform ease-in-out 0.2s;
          }

          .environ {
            color: #4E66E4;
            font-weight: 700;
            font-size: 13px;
          }
        }
      }
    }
  }
}



.troupeHistory {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 1200px;
  margin-top: 50px;
  transform: scale(1.1);

  .colorGradientLeft {
    display: flex;
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  .colorGradientRight {
    display: flex;
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  .subtitle {
    margin-top: 120px;
    background: linear-gradient(45deg, #3D88E4, #60D1BF);
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
  }

  .title {
    margin-top: 0;
    font-size: 42px;
    font-weight: 600;
  }

  .threeSteps {
    display: flex;
    margin-top: 60px;

    .textComponent {

      .titleTextComponent {
        font-size: 28px;
        font-weight: 600;
        width: 300px;
        margin-bottom: 0px;
      }

      .titleEnd {
        background-color: #4E66E4;
        -webkit-background-clip: text;
        color: transparent;
      }

      .subtitleTextComponent {
        color: #7D88A4;
        width: 350px;
        font-size: 14px;
      }
    }

    .secret1 {
      margin-top: -50px;
      border-radius: 10px;
      width: 300px;
      height: 200px;
      object-fit: cover;
      margin-bottom: 70px;
    }

    .secret2 {
      margin-top: 70px;
      border-radius: 10px;
      width: 300px;
      height: 200px;
      object-fit: cover;
      margin-bottom: 70px;
    }

    .secret3 {
      margin-top: 120px;
      border-radius: 10px;
      width: 300px;
      height: 200px;
      object-fit: cover;
      margin-bottom: 25px;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 50px;
      margin-right: 50px;

      .square {
        width: 40px;
        height: 40px;
        background: #4E66E4;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 17px;
      }

      .blackBar {
        width: 3px;
        height: 250px;
        background-color: #2D69F5;

      }

    }
  }

}

.troupeHistoryMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  margin-bottom: 80px;

  .colorGradientLeft {
    display: flex;
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    opacity: 0.5;
  }

  .colorGradientRight {
    display: flex;
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    opacity: 0.5;
  }

  .subtitle {
    margin-top: 60px;
    background: #4E66E4;
    font-weight: 700;
    -webkit-background-clip: text;
    color: transparent;
    max-width: 90vw;
  }

  .title {
    margin-top: 0;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 25px;
    max-width: 90vw;
    text-align: center;
  }

  .threeSteps {
    display: flex;
    margin-top: 60px;

    .textComponent {

      .titleTextComponent {
        font-size: 28px;
        font-weight: 600;
        width: 300px;
        margin-bottom: 0px;
      }

      .titleEnd {
        background: #4E66E4;
        -webkit-background-clip: text;
        color: transparent;
      }

      .subtitleTextComponent {
        color: #7D88A4;
        width: 350px;
        font-size: 14px;
      }
    }

    @media (max-width: 768px) {
      .textComponent {

        .subtitleTextComponent {
          font-size: 15px;
        }
      }

      .secret1 {
        width: 200px;
        margin-bottom: 30px;
      }
    }

    .square {
      width: 40px;
      height: 40px;
      background: #4E66E4;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 18px;
      margin-right: 30px;
    }

    .secret1 {
      width: 250px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 30px;
    }

    .secret2 {
      margin-top: 70px;
      width: 250px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    .secret3 {
      margin-top: 70px;
      width: 250px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
}

.containerLastPart {
  background-color: rgba(78, 102, 228, 0.05);
  width: 100vw;
  padding-bottom: 50px;
  .lastPart {
    margin-left: 5vw;
    width: 44vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;


    .aPropos {
      color: #4E66E4;
      font-weight: 700;
      font-size: 18px;
      margin-top: 100px;
    }

    .titleLastPart {
      margin-top: 0px;
      font-weight: 600;
      font-size: 45px;
      margin-bottom: 0px;
      text-align: center;
    }

    .containerContactInfos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      gap: 60px;
      flex-wrap: wrap;
    }

    .subtitleLastPart {
      margin-top: 40px;
      width: 700px;
      text-align: center;
      color: #7D88A4;
      line-height: 23px;
      max-width: 90vw;
    }

    .participateEvent:hover {
      transform: scale(1.02);
    }

    .participateEvent {
      margin-top: 40px;
      background-color: #4E66E4;
      padding: 15px 50px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 15px;
      border-radius: 7px;
      transition: transform ease-in-out 0.2s;
    }

    .participantsEvent {
      font-size: 14px;
      opacity: 0.5;
    }


    .imagesLastPart {
      margin-top: 80px;
      display: flex;
      gap: 5px;

      .imgLastPart {
        width: 33%;
        object-fit: cover;
        height: 300px;
      }
    }

    @media (max-width: 600px) { // Ajustement pour 2 carrés par ligne
      .imagesLastPart {
        flex-direction: column-reverse;

        .imgLastPart {
          width: 100%;
        }
      }
    }
  }

}

@media (max-width: 800px) { // Ajustement pour 2 carrés par ligne
  .containerLastPart {


    .lastPart {

      width: 90vw;

      .aPropos {
        color: #4E66E4;
        font-weight: 700;
        font-size: 17px;
        margin-top: 50px;
        margin-bottom: 20px;
      }

      .titleLastPart {
        font-size: 35px;
      }

      .containerContactInfos {
        gap: 40px;

      }
    }
  }

}

.bottomLandingPage {
  margin-top: 10px;
  width: 100vw;
  padding-left: 25px;
  opacity: 0.5;
  font-size: 13px;
  margin-bottom: 10px;
}
