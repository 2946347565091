.homePage {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;

  .step {
    padding-top: 100px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    overflow-x: scroll;
    max-height: calc(100vh - 200px);
  }

  @media (max-width: 980px) { // Ajustement pour 2 carrés par ligne
    .step {
      width: 100vw;
    }
  }

  .rightPart {
    width: 50vw;
    height: calc(100vh -  0px);
    overflow: hidden;

    .firstImage {
      width: 50vw;
      height: 45vh;
      object-fit: cover;
      margin-bottom: 2px;
    }

    .secondImage {
      width: 25vw;
      height: calc(55vh - 0px);
      object-fit: cover;
    }
  }

  .headerForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50vw - 40px);
    position: absolute;
    top: 0;
    padding: 20px 20px;
    background-color: #ffffff;

    .buttonBack:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    .buttonBack {
      white-space: nowrap;
      border: solid 1px #4E66E4;
      color: #4E66E4;
      font-size: 13px;
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 99px;
      transition: opacity ease-in-out 0.2s;
      display: flex;
      align-items: center;

      .leftIcon {
        width: 10px;
        object-fit: contain;
        margin-right: 5px;
      }
    }

    .listStepsIndicator {
      display: flex;
      align-items: center;
      gap: 10px;

      .stepIndicator {
        background-color: #EAEAEA;
        height: 3px;
        border-radius: 10px;
        width: 30px;
      }
    }


  }

  @media (max-width: 980px) { // Ajustement pour 2 carrés par ligne
    .headerForm {
      width: calc(100vw - 40px);
    }
  }

  @media (max-width: 600px) { // Ajustement pour 2 carrés par ligne
    .headerForm {
      flex-direction: column;
      gap: 20px;
    }
  }
}
