
$color-primary: #007BFF;
$color-secondary: #55a7ff;
$color-tertiary: #9dcbff;
$color-light: #F8F9FA;
$color-dark: #343A40;
$color-gray: #6C757D;

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

// Transitions
$transition-base: all 0.3s ease-in-out;

body {
  margin: 0rem;
  font-family: $font-family-base;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;

  a {
    text-decoration: none;
  }

  div::-webkit-scrollbar {
    display: none; /* Pour les navigateurs WebKit comme Safari et Chrome */
  }

  div {
    -ms-overflow-style: none; /* Pour Internet Explorer 10+ */
    scrollbar-width: none; /* Pour Firefox */
  }


}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}
